<template>
  <div
    v-if="activePlayer"
    :style="playerType === 'text' ? 'background: transparent !important;' : ''"
    class="player-container player-container__default"
    :class="`${
      playerType !== 'text' || playerType !== 'quiz' || !playerType
        ? `max-player-height is-${playerType}`
        : ''
    }`"
  >
    <div
      :style="`width: 100%; ${playerType === 'text' ? 'overflow: auto;' : 'overflow: hidden;'}`"
      :class="{
        'player relativePosicao': playerType !== 'iframe' && !isMobile,
        playerIframe: playerType === 'iframe' && !isMobile,
        'isVimeo': playerType === 'vimeo',
        isQuizV2: playerType === 'quiz',
      }"  
      >
      <div>
        <Panda :key="componentKey" :isMobile="isMobile" v-if="playerType === 'panda'" />
        <YouTubePlayer :key="componentKey" :isMobile="isMobile" v-if="playerType === 'youtube'" />
        <Vimeo :key="componentKey" :isMobile="isMobile" v-if="playerType === 'vimeo'" />
        <VIframe :key="componentKey" :isMobile="isMobile" v-if="playerType === 'iframe'" />
        <Audio :key="componentKey" :isMobile="isMobile" v-if="playerType === 'audio'" />
        <Pdf :key="componentKey" :isMobile="isMobile" v-if="playerType === 'pdf'"  />
        <Quizz :key="componentKey" :isMobile="isMobile" v-if="playerType === 'quiz'" />
        <LessonText :fromPlayer="true" :key="componentKey" :isMobile="isMobile" v-if="playerType === 'text'"/>
      </div>
    </div>
  </div>
  <LoadingPlayer v-else />
</template>

<script>
import Panda from "@/components/V2/player/playersType/panda/index";
import Audio from "@/components/V2/player/playersType/audio/index";
import Quizz from "@/components/V2/player/playersType/quizz/index";
import YouTubePlayer from "./youtube/index.vue";
import VIframe from "./viframe/index.vue";
import Vimeo from "./vimeo/index.vue";
import Pdf from "./pdf/index.vue";
import LessonText from "./LessonText.vue";
import { mapGetters } from "vuex";
import LoadingPlayer from "./LoadingPlayer.vue";

export default {
  data() {
    return {
      viewPdfContent: false,
    };
  },
  props: [
    "playerStyle",
    "apiLesson",
    "isMobile",
    "quizData",
    "memberPlanStatus",
    "numberLimitAlunoPercentage",
  ],
  computed: {
    ...mapGetters([
      "getCurrentCourse",
      "getCurrentModule",
      "getCurrentLesson",
      "getNextLesson",
      "getPrevLesson",
      "getPrevModule",
      "getNextModule",
      "getHasNextModule",
      "getHasPrevModule",
      "getHasPrevLesson",
      "getHasNextLesson",
      "getUserRole",
      "getCurrentLesson",
      "getPlayerStatus",
    ]),
    activePlayer() {
      return this.getPlayerStatus === "playing" && this.getCurrentLesson.is_liberated;
    },
    playerType() {
      return this.getCurrentLesson.mediaType || false;
    },
    componentKey() {
      return this.getCurrentLesson.title || null;
    },
  },
  methods: {
    openModalPdf() {
      this.$root.$emit("bv::show::modal", "modal-show-pdf", "#btnShow");
    },
  },
  components: {
    YouTubePlayer,
    Panda,
    Audio,
    Quizz,
    VIframe,
    Vimeo,
    LessonText,
    Pdf,
    LoadingPlayer,
  },
};
</script>

<style scoped lang="scss">
.pdf {
  padding: 70px 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.player-internal {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  background: #000;
  border-radius: 6px;
}

.is-pdf {
  align-items: start !important;
}

.player-container {
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  padding-bottom: 0px;
  background: var(--background2-v2);

  &.is-quiz,
  &.is-audio {
    max-height: 100%;
    padding-bottom: 0;
  }
  &.is-pdf {
    align-items: start !important;
  }

  __default {
    display: flex;
    width: 100%;
  }

  __theater {
    min-height: 530px;
    display: flex;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .player-container {
    display: flex;
    width: 100%;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    position: relative;
    background: var(--background2-v2);
    border-radius: 20px;

    &.is-pdf {
    align-items: start !important;
  }


    __default {
      display: flex;
      width: 100%;
    }

    __theater {
      min-height: 530px;
      display: flex;
      width: 100%;
    }
  }
}
.max-player-height {
  max-height: 530px;
}
</style>
