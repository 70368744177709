<template>
  <section class="player-v2">
    <audio
      style="display: none"
      :ref="`playerV2-${index}`"
      :id="`playerV2-${index}`"
    >
      <source :src="audio.cdn_url" :type="audio.mime" />
    </audio>

    <img
      class="lesson-thumb d-none d-md-block"
      :src="audio.cover"
      alt="lesson thumb"
    />
    <section class="content">
      <section class="w-100 d-flex align-items-center">
        <img
          class="lesson-thumb d-block d-md-none mr-4"
          :src="audio.cover"
          alt="lesson thumb"
        />
        <section class="b w-100">
          <p class="title">{{ audio.title }}</p>
        </section>
        <section class="e d-block d-md-none ml-4">
          <button class="play-btn" @click="toggleAudio">
            <svg
              width="14"
              height="17"
              viewBox="0 0 14 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="svg-icon"
              v-if="!isPlaying"
            >
              <path
                d="M0 2.68537C0 1.0985 1.75947 0.143656 3.08998 1.00849L12.4202 7.07311C13.6335 7.8618 13.6335 9.6382 12.4202 10.4269L3.08997 16.4915C1.75947 17.3563 0 16.4015 0 14.8146V2.68537Z"
              />
            </svg>

            <svg
              height="17px"
              id="Layer_1"
              style="enable-background: new 0 0 512 512"
              version="1.1"
              viewBox="0 0 512 512"
              width="17px"
              xml:space="preserve"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              class="svg-icon"
              v-else
            >
              <g>
                <path
                  d="M224,435.8V76.1c0-6.7-5.4-12.1-12.2-12.1h-71.6c-6.8,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6   C218.6,448,224,442.6,224,435.8z"
                />
                <path
                  d="M371.8,64h-71.6c-6.7,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6c6.7,0,12.2-5.4,12.2-12.2V76.1   C384,69.4,378.6,64,371.8,64z"
                />
              </g>
            </svg>
          </button>
        </section>
      </section>
      <section class="c">
        <p class="description" v-html="description"></p>
      </section>
      <section class="d">
        <section
          class="slider"
          @mouseenter="isHover = true"
          @mouseleave="isHover = false"
        >
          <input
            class="custom-range"
            v-model="playbackTime"
            type="range"
            min="0"
            :max="audioDuration"
            :class="{ rangeDotHidden: !isHover }"
          />
          <section
            class="current"
            :style="{ width: currentTimeFormated(playbackTime, audioDuration) }"
            :hovered="isHover"
          ></section>
          <section class="bar"></section>
        </section>
        <span class="currentTime">
          {{
            playbackTime ? formatTime(playbackTime) : formatTime(audioDuration)
          }}
        </span>
      </section>
    </section>
    <section class="e d-none d-md-flex">
      <button class="play-btn" @click="toggleAudio">
        <svg
          width="14"
          height="17"
          viewBox="0 0 14 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="svg-icon"
          v-if="!isPlaying"
        >
          <path
            d="M0 2.68537C0 1.0985 1.75947 0.143656 3.08998 1.00849L12.4202 7.07311C13.6335 7.8618 13.6335 9.6382 12.4202 10.4269L3.08997 16.4915C1.75947 17.3563 0 16.4015 0 14.8146V2.68537Z"
          />
        </svg>

        <svg
          height="512px"
          id="Layer_1"
          style="enable-background: new 0 0 512 512"
          version="1.1"
          viewBox="0 0 512 512"
          width="512px"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          class="svg-icon"
          v-else
        >
          <g>
            <path
              d="M224,435.8V76.1c0-6.7-5.4-12.1-12.2-12.1h-71.6c-6.8,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6   C218.6,448,224,442.6,224,435.8z"
            />
            <path
              d="M371.8,64h-71.6c-6.7,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6c6.7,0,12.2-5.4,12.2-12.2V76.1   C384,69.4,378.6,64,371.8,64z"
            />
          </g>
        </svg>
      </button>
    </section>
  </section>
</template>

<script>
// icons
import pause from "@/assets/icons/pause.svg";
export default {
  props: ["audio", "index", "description"],
  data() {
    return {
      playbackTime: 0,
      audioDuration: 0,
      audioLoaded: false,
      isPlaying: false,
      isHover: false,
      pause,
    };
  },
  methods: {
    formatTime(seconds) {
      if (seconds) {
        const format = (val) => `0${Math.floor(val)}`.slice(-2);
        let minutes = (seconds % 3600) / 60;
        return [minutes, seconds % 60].map(format).join(":");
      }
      return "00:00";
    },
    initSlider() {
      var audio = this.$refs[`playerV2-${this.index}`];
      if (audio) {
        this.audioDuration = Math.round(audio.duration);
      }
    },
    convertTime(seconds) {
      const format = (val) => `0${Math.floor(val)}`.slice(-2);
      let minutes = (seconds % 3600) / 60;
      return [minutes, seconds % 60].map(format).join(":");
    },
    totalTime() {
      let audio = this.$refs[`playerV2-${this.index}`];
      if (audio) {
        let seconds = audio.duration;
        return this.convertTime(seconds);
      } else {
        return "00:00";
      }
    },
    elapsedTime() {
      var audio = this.$refs[`playerV2-${this.index}`];
      if (audio) {
        var seconds = audio.currentTime;
        return this.convertTime(seconds);
      } else {
        return "00:00";
      }
    },
    playbackListener() {
      var audio = this.$refs[`playerV2-${this.index}`];
      this.playbackTime = audio.currentTime;

      audio.addEventListener("ended", this.endListener);
      audio.addEventListener("pause", this.pauseListener);
    },
    pauseListener() {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },
    endListener() {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },
    cleanupListeners() {
      var audio = this.$refs[`playerV2-${this.index}`];
      audio.removeEventListener("timeupdate", this.playbackListener);
      audio.removeEventListener("ended", this.endListener);
      audio.removeEventListener("pause", this.pauseListener);
    },
    toggleAudio() {
      var audio = this.$refs[`playerV2-${this.index}`];
      if (audio.paused) {
        audio.play();
        this.isPlaying = true;
      } else {
        audio.pause();
        this.isPlaying = false;
      }
    },
    currentTimeFormated(current, duration) {
      return `${((current / duration) * 100).toFixed(2)}%`;
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      var audio = this.$refs[`playerV2-${this.index}`];
      audio.addEventListener(
        "loadedmetadata",
        function () {
          this.initSlider();
        }.bind(this)
      );
      audio.addEventListener(
        "canplay",
        function () {
          this.audioLoaded = true;
        }.bind(this)
      );
      this.$watch("isPlaying", function () {
        if (this.isPlaying) {
          var audio = this.$refs[`playerV2-${this.index}`];
          this.initSlider();
          if (!this.listenerActive) {
            this.listenerActive = true;
            audio.addEventListener("timeupdate", this.playbackListener);
          }
        }
      });
      this.$watch("playbackTime", function () {
        let diff = Math.abs(
          this.playbackTime - this.$refs[`playerV2-${this.index}`].currentTime
        );

        if (diff > 0.01) {
          this.$refs[`playerV2-${this.index}`].currentTime = this.playbackTime;
        }
      });
    });
  },
};
</script>

<style lang="scss">
.player-v2 {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  width: 100%;
  min-height: 150px;
  padding: 25px 30px;
  border-radius: 20px;
  background: var(--closes-bg);
  color: var(--text-color);
  gap: 15px;

  display: flex;

  @media (max-width: 768px) {
    padding: 15px 20px;
  }

  .lesson-thumb {
    width: 150px;
    height: 150px;
    min-width: 150px;
    min-height: 150px;
    border-radius: 20px;

    @media (max-width: 768px) {
      width: 50px;
      height: 50px;
      min-width: 50px;
      min-height: 50px;
      border-radius: 5px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
  }
}

.svg-icon {
  height: 17px;
  fill: var(--background);
}
</style>