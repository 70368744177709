<template>
  <div class="player-container">
    <show-player
      v-if="canShowPlayer"
      :source="getCurrentLesson.source"
      :isMobile="isMobile"
    />
    <loading-player v-else />
  </div>
</template>

<script>
import ShowPlayer from './show-player.vue'
import LoadingPlayer from '../LoadingPlayer.vue'
import {mapGetters} from "vuex";

export default {
  props: ['isMobile'],
  components: {
    ShowPlayer,
    LoadingPlayer
  },
  computed: {
    ...mapGetters(['getPlayerStatus', 'getCurrentLesson']),
    canShowPlayer() {
      return this.getPlayerStatus === 'playing'
    }
  }
}
</script>

<style scoped>
  .player-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
  }
</style>