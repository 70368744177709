<template>
  <div v-if="getPlayerStatus === 'playing'" class="lesson-text-container">
    <LessonDescription :fromPlayer="fromPlayer"/>
  </div>
  <LoadingPlayer v-else />
</template>

<script>
import { mapGetters } from "vuex";
import LoadingPlayer from "./LoadingPlayer.vue";
import LessonDescription from "../lessonDescription/index.vue";
export default {
  props: ['isMobile', 'fromPlayer'],
  computed: {
    ...mapGetters(['getCurrentLesson', 'getPlayerStatus'])
  },
  components: {
    LoadingPlayer,
    LessonDescription
  }
}
</script>

<style>
.lesson-text-container {
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  color: var(--fontcolor-v2);
}
</style>