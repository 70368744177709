<template>
  <div :style="`display: flex; width: 100%; justify-content: center; max-height: ${heightDinamic}px; min-height: ${heightDinamic}px;`">
    <vimeo-player :playerHeight="heightDinamic" v-if="renderComponent" @play="playerStatus = 'playing'" @pause="playerStatus = 'paused'" class="playerVimeo" ref="player" :autoplay="false" :video-url="videoURL"></vimeo-player>
  </div>
</template>
<script>
// import notify from "@/services/libs/notificacao";
import { mapGetters } from "vuex";

export default {
  props:['isMobile'],
  watch:{
    videoURL(){
      this.forceRerender();
    },
  },
  computed: {
    ...mapGetters(['getCurrentLesson']),
    heightDinamic() {
      if (this.isMobile) {
        return '250'
      } else {
        return '530'
      }
    },
    videoURL() {
      var url = this.getCurrentLesson.source;
      var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
      var match = url.match(regExp);
      if (match) {
        return url ;
      }
      return '';
    },
    videoID() {
      var url = this.getCurrentLesson.source;
      var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
      var match = url.match(regExp);
      if (match) {
        return match[2];
      }
      return '';
    },
  },
  data() {
    return {
      renderComponent: true,
      height: 530,
      options: {
        muted: false,
        autoplay: true,
      },
      playerReady: false,
      t:'',
      playerStatus: 'started'
    }
  },
  methods: {
    forceRerender () {
      this.renderComponent = false;

      this. $nextTick (() => {
        this.renderComponent = true;
      });
    },
    onReady() {
      this.playerReady = true
    },
    play () {
      this.$refs.player.play();
    },
    pause () {
      this.$refs.player.pause()
    },
    getDurationTime(){
      try {
        let player = this.$refs.player;
        return player.player.getDuration().then(function(duration) {
          return duration;
        })
      } catch(e) {
        //erro
      }
    },
    getCurrentTime(){
      try {
        let player = this.$refs.player;
        return  player.player.getCurrentTime().then(function(duration) {
          return duration;
        })
      } catch(e) {
        //erro
      }
    },
    async calcPercent(){
      try {
        if (this.getCurrentLesson && this.getCurrentLesson.percent == 100) return;
        var current = await this.getCurrentTime();
        var total = await this.getDurationTime();

        if (!current || !total) return;
        if (isNaN(total) || isNaN(total)) return;

        current = parseInt(current, 10);
        total = parseInt(total, 10);
        var calc = (current/total)*100;
        if (isNaN(calc)) return;
        calc = parseInt(calc, 10);
        this.$store.commit('setCurrentLesson', {
          ...this.$store.getters.getCurrentLesson,
          percent: calc
        })
      } catch (e) {
         console.log('erro no vimeo', e);
      }
    },
    update () {
      this.$refs.player.update(this.videoURL);
      this.forceRerender();
    },
    verifyIfCountPercent() {
      this.calcPercent();
      if (this.getCurrentLesson.mediaType === 'vimeo') {
        setTimeout(() => {
          this.verifyIfCountPercent();
        }, 1000);
      }
    },
  },
  mounted(){
    this.verifyIfCountPercent();
  },
}
</script>

<style scoped>
.toZindex .playerVimeo {
  z-index: 99999999999999999;
}
.playerVimeo {
  display: flex;
  justify-content: center;
  min-width: 100%;
}
iframe {
  width: 100%;
}

</style>