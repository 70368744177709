<template>
  <div>
    <iframe ref="iframe" :src="source"
    style="border: none; position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
    allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
    allowfullscreen="true" 
    :style="`${calcHeight}`"
    >
  </iframe>
  </div>
</template>
<script>
export default {
  props:['source', 'isMobile'],
  mounted(){
    this.sourceTreated();
    window.addEventListener("resize", () => {
      this.sourceTreated();
    });
  },
  computed: {
    calcHeight() {
      if (this.isMobile) {
        return 'min-width: 100%; position: relative; aspect-ratio: 16/9;'
      } else {
        return 'min-width: 100%; position: relative; aspect-ratio: 16/9;'
      }
    },
  },
  methods:{
    sourceTreated() {
      let iframe = this.$refs.iframe
      if(iframe && iframe.style && this.source.includes('scaleup') && window.innerWidth < 1500){
        iframe.style.minWidth = '100%';
        iframe.style.minHeight = `${iframe.clientWidth * 0.5625}px`;
        return;
      }else if(iframe && iframe.style && this.source.includes('scaleup')  && window.innerWidth > 1500){
        iframe.style.minWidth = '889px';
        iframe.style.minHeight = '560px';
      }
		}
  }
}
</script>

<style scoped>
iframe {
  max-width: 980px;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>