<template>
  <section class="player-audios" v-if="audios.length">
    <section v-for="(item, index) in audios" :key="index">
      <custom-player
        :audio="item"
        :index="index + 1"
        :description="index === 0 ? getCurrentLesson.content : ''"
      />
    </section>
  </section>
</template>

<script>
// Core
import { mapGetters } from "vuex";
// Components
import CustomPlayer from "./CustomPlayer.vue";

export default {
  name: "PlayerAudioV2",
  components: {
    CustomPlayer,
  },
  data: () => ({
    audios: [],
  }),
  computed: {
    ...mapGetters(["getCurrentLesson"]),
    description() {
      return this.getCurrentLesson.content || "";
    },
  },
  methods: {
    formatTime(seconds) {
      if (seconds) {
        const format = (val) => `0${Math.floor(val)}`.slice(-2);
        let minutes = (seconds % 3600) / 60;
        return [minutes, seconds % 60].map(format).join(":");
      }
      return "00:00";
    },
    initSlider() {
      const audio = this.$refs.playerV2;
      if (audio) {
        this.duration = Math.round(audio.duration);
      }
    },
    toggleAudio(item, index) {
      const audio = document.querySelector(`#playerV2-${index}`);
      if (audio.paused) {
        audio.play();
        audio.addEventListener("timeupdate", () => {
          item.current = audio.currentTime;
        });

        this.$watch(
          item,
          () => {
            console.log(item);
          },
          {
            deep: true,
          }
        );
      } else {
        audio.pause();
        audio.removeEventListener("timeupdate", () => ({}));
      }
      item.isPlaying = !audio.paused;
    },
    currentTimeFormated(current, duration) {
      return `${((current / duration) * 100).toFixed(2)}%`;
    },
    async setAudios() {
      this.audios =
        this.getCurrentLesson?.audios.map((item) => ({
          ...item,
          isPlaying: false,
          isHover: false,
          duration: 0,
          current: 0,
        })) || [];
      setTimeout(() => {
        this.setAudioDuration();
      }, 500);
    },
    setAudioDuration() {
      this.audios.forEach((item, index) => {
        const audio = document.querySelector(`#playerV2-${index + 1}`);
        item.duration = audio.duration;
      });
    },
  },
  mounted() {
    this.setAudios();
  },
};
</script>

<style lang="scss">
.video-player-container {
  background: transparent !important;
}

.player-audios {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.player-v2 {
  .d {
    display: flex;
    align-items: flex-end;
    position: relative;
  }

  .e {
    max-width: 40px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @media (max-width: 767px) {
      align-items: flex-start;
    }
  }

  .title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  .description {
    text-align: start;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    p * {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 150%;
      color: #9a9a9a !important;
    }
  }

  .slider {
    height: 40px;
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
    position: relative;
  }

  .custom-range {
    position: absolute;
    width: 100%;
    z-index: 40;
    -webkit-appearance: none;
    appearance: none;
    accent-color: white;
    opacity: 0;
  }

  .current {
    pointer-events: none;
    position: absolute;
    height: 4px;
    z-index: 10;
    width: 0;
    background: #01caa6;
    border-radius: 2px;
  }

  .bar {
    position: absolute;
    height: 4px;
    z-index: -0;
    width: 100%;
    background: var(--slider);
    border-radius: 2px;
  }

  .currentTime {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    height: 40px;
    color: #9a9a9a;
  }

  .play-btn {
    height: 40px;
    min-width: 40px;
    outline: none;
    border: none;
    border-radius: 50%;
    background: var(--contrast-bg);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 17px;
      color: var(--background);
    }
  }
}

.rangeDotHidden[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
  background: red;
}
</style>