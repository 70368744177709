var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.activePlayer)?_c('div',{staticClass:"player-container player-container__default",class:`${
    _vm.playerType !== 'text' || _vm.playerType !== 'quiz' || !_vm.playerType
      ? `max-player-height is-${_vm.playerType}`
      : ''
  }`,style:(_vm.playerType === 'text' ? 'background: transparent !important;' : '')},[_c('div',{class:{
      'player relativePosicao': _vm.playerType !== 'iframe' && !_vm.isMobile,
      playerIframe: _vm.playerType === 'iframe' && !_vm.isMobile,
      'isVimeo': _vm.playerType === 'vimeo',
      isQuizV2: _vm.playerType === 'quiz',
    },style:(`width: 100%; ${_vm.playerType === 'text' ? 'overflow: auto;' : 'overflow: hidden;'}`)},[_c('div',[(_vm.playerType === 'panda')?_c('Panda',{key:_vm.componentKey,attrs:{"isMobile":_vm.isMobile}}):_vm._e(),(_vm.playerType === 'youtube')?_c('YouTubePlayer',{key:_vm.componentKey,attrs:{"isMobile":_vm.isMobile}}):_vm._e(),(_vm.playerType === 'vimeo')?_c('Vimeo',{key:_vm.componentKey,attrs:{"isMobile":_vm.isMobile}}):_vm._e(),(_vm.playerType === 'iframe')?_c('VIframe',{key:_vm.componentKey,attrs:{"isMobile":_vm.isMobile}}):_vm._e(),(_vm.playerType === 'audio')?_c('Audio',{key:_vm.componentKey,attrs:{"isMobile":_vm.isMobile}}):_vm._e(),(_vm.playerType === 'pdf')?_c('Pdf',{key:_vm.componentKey,attrs:{"isMobile":_vm.isMobile}}):_vm._e(),(_vm.playerType === 'quiz')?_c('Quizz',{key:_vm.componentKey,attrs:{"isMobile":_vm.isMobile}}):_vm._e(),(_vm.playerType === 'text')?_c('LessonText',{key:_vm.componentKey,attrs:{"fromPlayer":true,"isMobile":_vm.isMobile}}):_vm._e()],1)])]):_c('LoadingPlayer')
}
var staticRenderFns = []

export { render, staticRenderFns }