<template>
  <div class="lesson_description_container">
    <slot></slot>
    <v-md-editor
    :class="{ 'spacement': fromPlayer }" class="lesson_description"
      :value="getCurrentLesson.content"
      mode="preview"
    ></v-md-editor>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  export default {
    props: ['fromPlayer'],
    computed: {
      ...mapGetters(['getCurrentLesson']),
    }
  }
</script>

<style>
  .spacement {
    max-height: 530px !important;
    padding-top: 40px !important;
    overflow: auto !important;
  }
  .lesson_description_container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 6px;
    margin-bottom: 6px;
    padding-right: 20px;
    padding-left: 20px;
    position: relative;
    border-top: 1px dashed var(--bordercolor-v2);
    border-bottom: 1px dashed var(--bordercolor-v2);
    border-width: 2px;
    overflow: auto;
  }
  @media screen and (min-width: 968px) {
    .lesson_description_container {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      padding-right: 0px;
      padding-left: 0px;
      margin-top: 20px;
      margin-bottom: 50px;
      padding-bottom: 50px;
      border-top: 1px dashed var(--bordercolor-v2);
      border-bottom: 1px dashed var(--bordercolor-v2);
      border-width: 2px;
      overflow: auto;
    }
  }
  .lesson_description {
    color: var(--fontcolor-v2);
    /* white-space: pre-wrap !important; */
    overflow: auto;
    background: var(--background-v2);
  }
  .lesson_description::-webkit-scrollbar {
    width: 6px;
  }

  .lesson_description::-webkit-scrollbar-track {
    background: var(--background2-v2);
    border-radius: 6px;
  }

  .lesson_description::-webkit-scrollbar-thumb {
    background-color: var(--fontcolor2-v2); 
    border-radius: 20px;
    margin-top: 35px;
  }
  .ql-size-large {
    font-size: 1.5rem !important;
  }
  .ql-size-huge {
    font-size: 2.5em !important;
  }
  .ql-size-small {
    font-size: 0.75em !important;
  }
</style>