<template>
  <div :style="calcHeight" class="player-container" v-if="getCurrentLesson.can_be_displayed">
    <show-player
      v-if="!getCurrentLesson.error"
      :isMobile="isMobile"
      :porcentagem="0"
    />
    <div v-else>
      {{
        $t('player.erro_panda_seller')
      }}
    </div>
    <is-not-converted v-if="VideoIsNotConverted"/>
    <error-on-converter v-if="VideoErrorProcessing"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Cookies from "js-cookie";

export default {
  props: ['isMobile'],
  components: {
    isNotConverted: () => import('../panda/is-not-converted.vue'),
    ErrorOnConverter: () => import('../panda/error-on-converter.vue'),
    ShowPlayer: () => import('./show-player.vue'),
  },
  computed: {
    ...mapGetters(['getCurrentLesson', 'getMemberPlanStatus']),
    VideoIsNotConverted() {
      return this.getCurrentLesson.mediaType === 'panda' && 
      this.getCurrentLesson.mediaType.media && 
      this.getCurrentLesson.media.status !== 'CONVERTED'
    },
    VideoErrorProcessing() {
      return this.getCurrentLesson.mediaType === 'panda' && 
      this.getCurrentLesson.mediaType.media && 
      this.getCurrentLesson.media.status === 'FAILED'
    },
    isExceededStorage() {
      return JSON.parse(Cookies.get('exceeded_storage'))
    },
    calcHeight() {
      if (this.isMobile) {
        return 'min-height: 250px !important;'
      } else {
        return 'min-height: 530px !important;'
      }
    }
  }
}
</script>

<style scoped>
.player-container {
  width: 100%;
  position: relative;
}
</style>