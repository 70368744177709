<template>
	<div class="container-player">
		<IframeAux class="playerIFrame" :isMobile="isMobile" id="iframe-player2" :source="source"></IframeAux>
	</div>
</template>
<script>
// import notify from "@/services/libs/notificacao";
import IframeAux from './VideoPlayerIframeAux.vue'
export default {
    props:['source', 'porcentagem', 'height', 'isMobile'],
	data() {
		return {
			options: {
				muted: false,
                autoplay: true,
			},
			playerReady: false,
			t:''
		}
	},
	components: { IframeAux },


}
</script>

<style>
.toZindex .playerVimeo {
    z-index: 99999999999999999;
}

#iframe-player2 iframe{
	width: -webkit-fill-available !important;
}

#iframe-player2 div {
  display: flex;
	max-width: 980px;
  justify-content: center !important;
  align-items: center !important;
  background: #000;
}
.playerIFrame {
  display: flex;
  justify-content: center;
  min-width: 100%;
}

.container-player{
	display: flex; 
	justify-content: center; 
	align-items: center; 
	min-width: 80%;
}

@media (min-width: 2000px) {
.container-player{
	min-width: 60%;
}
}

@media (min-width: 2600px) {
.container-player{
	min-width: 40%;
}
}
</style>